export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },
  {
    title: "Certificates",
    route: "certificate",
    icon: "AwardIcon",
  },
  {
    title: "Programs",
    route: "certificate-program",
    icon: "BookOpenIcon",
  },
  {
    title: "Certificate Settings",
    icon: "ToolIcon",
    children: [
      {
        title: "Background",
        route: "certificate-background",
      },
      {
        title: "MetaData",
        route: "meta-data",
      },
      {
        title: "Certificate Template",
        route: "certificate-format",
      },
    ],
  },

  {
    title: "Settings",
    icon: "SettingsIcon",
    children: [
      {
        title: "Role",
        route: "role",
      },
      {
        title: "User",
        route: "user",
      },
    ],
  },
];
